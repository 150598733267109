<template>
    <div>
      <div class="mywrapper">
          <div class="page-banner">
              <div class="bannerChild">
                  <div>
                      <div>
                          <div class="evtSubject">
                              {{ oneEvent.summary_html }}
                          </div>
                      </div>
                  </div>
                  <div v-if="eventReg">
                    <form @submit.prevent="onSubmit" style="margin-top: 10px">
                        <div>
                            <input
                                class="evtInput"
                                type="email"
                                id="email"
                                v-model="email"
                                autofocus
                                placeholder="enter your email address">
                        </div>
                        <div class="">
                            <button
                                class="evtButton"
                                :disabled="$v.$invalid"
                                type="submit">
                                RSVP Now!
                            </button>
                        </div>
                    </form>
                  </div>
                  <div v-else >
                      <Sending2 />
                      <div class="fadeIn">
                        <div style="margin-top: -150px; z-index: -1;">
                            <div style="font-size: 18px; padding: 20px 0px 0px 0px;">
                            an invitation has been sent to:<br/>
                            </div>
                            <div style="font-size: 26px; font-weight: 700;">
                            {{ email }}<br/>
                            </div>
                            <div style="font-size: 14px; padding: 20px 0px 20px 0px">
                            if the invitation is not in your inbox<br/>please check your spam/junk folder
                            </div>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="page-evinfo">
              <div class="infoChild">
                  <div class="evtMonth">
                      {{ oneEvent.dtstart | moment("MMMM") }}
                  </div>
                  <div class="evtDay">
                      {{ oneEvent.dtstart | moment("DD") }}
                  </div>
              </div>
              <div class="dtChild">
                  <div class="evtHead">
                      Date And Time
                  </div>
                  <div class="evtText">
                      Start {{ oneEvent.dtstart | moment("MM/DD/YYYY") }} @ {{ oneEvent.dtstart | moment("h:mm a") }}<br />
                      End {{ oneEvent.dtend | moment("MM/DD/YYYY") }} @ {{ oneEvent.dtend | moment("h:mm a") }}
                  </div>
                  <div class="evtHead">
                      Location
                  </div>
                  <div v-if="oneEvent.location_html === null" class="evtText">
                      No Location Provided
                  </div>
                  <div v-else class="evtText">
                      {{ oneEvent.location_html }}
                  </div>
                  <div class="evtHead">
                      brought to you by:
                  </div>
                  <div class="evtText">
                      {{ oneEvent.organizer }}
                  </div>
              </div>
          </div>
          <div v-if="oneEvent.description_html === ' ' " ></div>
          <div v-else class="page-body">
              <div class="evtTitle">
                  Event Details
              </div>
              <div v-html="oneEvent.description_html"></div>
          </div>
      </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import { SNACKAPI } from '@/assets/js/api.js'
import { required, email } from 'vuelidate/lib/validators'
import Sending2 from '@/components/loading/Sending2.vue'

export default {
  name: 'InviteOne',
  props: ['eventid'],

  components: {
    Sending2
  },

  data () {
    return {
      eventReg: true,
      eventThankyou: false,
      snackUri: SNACKAPI,
      email: '',
      name: '',
      origin: 'landingpage',
      landing: ''
    }
  },

  validations: {
    email: {
      required,
      email
    }
  },

  computed: {
    ...mapState(['oneEvent']),

    inviteeUri: function () {
      return (
        this.snackUri +
        this.oneEvent.uid +
        '/invite?email=' +
        this.email +
        '&name=%20&origin=' +
        this.origin +
        '&landing=' +
        this.landing
      )
    }
  },

  methods: {

    onSubmit () {
      const formData = {
        name: this.name,
        email: this.email
      }
      console.log(formData)
      axios
        .get(this.inviteeUri)
        .then(response => {
          console.log(response)
          this.eventReg = false
          this.eventThankyou = true
          // this.$router.push({ name: 'thankyou', params: { eventid: this.$route.params.eventid } })
          this.resetForm()
        })
        .catch(error => {
          console.log(error)
          this.eventReg = false
          this.eventThankyou = true
          // this.$router.push({ name: 'thankyou', params: { eventid: this.$route.params.eventid } })
          // this.resetForm()
        })
    },

    resetForm () {
      console.log('Reseting Form Data')
      this.email = ''
    }
  }

}
</script>

<style scoped>
.mywrapper {
    font-family: 'Roboto', sans-serif;
    display: grid;
    background-color: #333;
    color: #333;
    max-width: 750px;
    margin: 5% auto;
    box-shadow: 0 10px 25px -3px rgba(0, 0, 0, 0.5), 0 14px 16px -2px rgba(0, 0, 0, 0.05);
    grid-gap: 0px;
    grid-template-columns: 2fr 1fr;
    grid-template-areas:
        "banner evinfo"
        "body body"
        "footer footer"
    ;
}

.page-body {
    grid-area: body;
    background-color: #fff;
    padding: 20px;
    border-bottom: solid 1px #999;
    border-top: solid 1px #999;
    text-align: left;
    min-height: 25vh;
}

.page-evinfo {
    grid-area: evinfo;
    background-color: rgb(255, 255, 255, .8);
    padding: 0;
    text-align: left;
}

.page-evdt {
    grid-area: evdt;
    background-color: rgb(255, 255, 255, .8);
    padding: 0;
    text-align: left;
}

.page-footer {
    grid-area: footer;
    background-color: lightgray;
    padding: 10px 20px;
    font-size: 11px;
    text-align: left;
}

.page-banner {
    grid-area: banner;
    color: white;
    padding: 20px;
    text-align: center;
    min-height: 25vh;
}

.infoChild {
    padding: 20px;
}

.dtChild {
    padding: 0 20px;
}

.evtLogo {
    max-width: 750px;
    min-height: 80px;
    margin: 5% auto;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: -3rem;
}

.evtSubject {
    font-size: 2.75rem;
    text-align: center;
    font-weight: 500;
    line-height: 1;
    padding: 25px 0 25px 0;
}

.thankyouText {
    font-size: 14px;
    padding-top: .5rem;
}

.evtMonth {
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1;
    padding-left: 5px;
}

.evtDay {
    font-size: 5rem;
    font-weight: 900;
    line-height: 1;
    padding: 0;
}

.evtHead {
    font-size: .75rem;
    font-weight: 300;
    line-height: 1;
    padding-bottom: 5px;
}

.evtText {
    font-size: .95rem;
    font-weight: 500;
    line-height: 1.2;
    padding-bottom: 20px;
}

.evtTitle {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.2;
    padding-bottom: 20px;
}

.evtInput {
    background-color: white;
    margin-top: 10px;
    border: solid 1px white;
    border-radius: 5px;
    color: #333;
    font-size: 16px;
    padding: 5px 15px;
    text-align: center;
}

.evtButton {
    background-color: #c53030;
    border: solid 0px #fff;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 16px;
    padding: 5px 15px;
}

.evtButton:hover {
    background-color: #fff5f5;
    color: #c53030;
    font-weight: 900;
    padding: 3.5px 15px;
}

.evtButton:active {
    position: relative;
    top: 1px;
}

.footerIcon {
    float: left;
    margin-right: 10px;
}

.footerText {
    margin-top: 2px;
}

@-webkit-keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

.fadeIn {
    animation: fadeIn 5.5s both;
    -webkit-animation: fadeIn 5.5s both;
}

@media (max-width: 750px) {
    .mywrapper {
        max-width: 400px;
        grid-template-columns: 1fr;
        grid-template-areas:
            "banner"
            "evinfo"
            "body"
            "footer"
        ;
    }

    .evtLogo {
        text-align: center;
        margin-bottom: -2rem;
    }

    .page-footer {
        text-align: center;
    }

    .footerIcon {
        min-height: 60px;
        display: block;
    }
}

</style>
