<template>
    <div>
        <div v-if="!oneEvent.uid" class="mt-4 text-3xl font-bold">
          404 - Event Not Found
        </div>
        <div v-else>
          <div v-if="!logoImg"></div>
          <div v-else class="mx-auto mt-5 text-center" style="max-width: 350px;">
            <img :src="logoImg" style="margin: 0 auto; max-height: 100px;">
          </div>
          <EventInviteOne v-bind:orgEmail="oneEvent.mailto" />
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import EventInviteOne from '@/components/event/EventInvite1.vue'

export default {
  name: 'Event',
  props: ['eventid'],

  components: {
    EventInviteOne
  },

  data () {
    return {
      logoImg: this.$route.query.img1
    }
  },

  created () {
    this.LOAD_EVENT(this.eventid)
  },

  mounted () {
    window.scrollTo(0, 0)
  },

  computed: {
    ...mapState([
      'oneEvent'
    ])
  },

  methods: {
    ...mapActions([
      'LOAD_EVENT'
    ])
  }

}
</script>

<style scoped>

</style>
